import React from 'react';
import theme from '../../../utils/theme';
import { Box, Button } from '@mui/material';
import ParameterTitle from './ParamTitle';
import Parameter from './Parameter';

const useStyles = {
  paramGroup: {
    marginTop: theme.spacing(4),
  },
  button: {
    height: '24px',
    border: '1px solid',
    borderRadius: '4px',
  },
};

const ParameterInfo: React.FC<{
  items?: any;
  definitions?: any;
  body?: any;
  components?: any;
  responses?: any;
}> = (props) => {
  const { definitions, components, items, body, responses } = props;
  const [selectedSchema, setSelectedSchema] = React.useState<any>(0);
  let groupedParametersArr: any[] = [];
  let schemaOptions: any[] = [];

  if (items) {
    groupedParametersArr = Object.values(
      props.items.reduce((group, parameter) => {
        const key = parameter['in'];
        group[key] ??= [];
        group[key].push(parameter);
        return group;
      }, {}),
    );
  }
  if (body && body.content) {
    const content = body.content;
    if (content['application/json']) {
      const schema = content['application/json'].schema;
      if (schema.anyOf) {
        schemaOptions = schema.anyOf;
        const bodyParam = {
          in: 'body',
          name: 'body',
          schema: schemaOptions[selectedSchema],
          schemaOptions: schemaOptions,
        };
        groupedParametersArr.push([bodyParam]);
      } else {
        const bodyParam = {
          in: 'body',
          name: 'body',
          schema: schema,
        };
        groupedParametersArr.push([bodyParam]);
      }
    }
    else if (content['multipart/form-data']) {
      const bodyParam = {
        in: 'body',
        name: 'body',
        schema: content['multipart/form-data'].schema,
      };
      groupedParametersArr.push([bodyParam]);
    }
  }
  if (responses && responses.content) {
    const content = responses.content;
    const schema = content['application/json']?.schema;
    if((schema?.properties || schema?.allOf) && !schema?.properties?.regions?.additionalProperties && !schema?.properties?.response?.description){
      if(schema?.properties) {
        const bodyParam = {
          in: 'response',
          name: 'response',
          schema: schema,
        };
        groupedParametersArr.push([bodyParam]);
      } else if (schema?.allOf) {
        const bodyParams = schema.allOf.map((updatedSchema: any) => ({
          in: 'response',
          name: 'response',
          schema: updatedSchema,
        }));
        groupedParametersArr.push(bodyParams); 
        }
    }
  }
  return (
    <>
      {groupedParametersArr.map((value: any, i) => {
        const isBodySchemaWithAnyOf = value.some((param: any) => param.schemaOptions);
        return (
          <Box key={i} sx={useStyles.paramGroup}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {value && <ParameterTitle items={value} />}
              {isBodySchemaWithAnyOf && (
                <Box sx={{ display: 'flex', gap: 1, paddingLeft: '8px' }}>
                  {schemaOptions.map((option: any, index: number) => (
                    <Button
                      key={index}
                      onClick={() => setSelectedSchema(index)}
                      variant={selectedSchema === index ? 'contained' : 'outlined'}
                      sx={useStyles.button}
                    >
                      {option.title}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
            {value &&
              value.map((param, i) =>
                <Parameter
                  param={param}
                  key={i}
                  definitions={definitions}
                  components={components}
                />
              )}
          </Box>
        );
      })}
    </>
  );
};

export default ParameterInfo;
