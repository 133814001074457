import React from 'react';
import apiRefMessages from '../../../utils/messages/en/apiRefMessages';
import theme, { COLORS } from '../../../utils/theme';
import Typography from '../../common/Typography';

const useStyles = {
  typographyStyle: {
    fontWeight: 'bold',
    color: COLORS.TEXT_SECONDARY,
    display: 'table-cell',
    paddingLeft: '18px',
    verticalAlign: 'bottom',
  },
};

const ParameterTitle: React.FC<{ items: any }> = (props) => {
  const { items } = props;
  let title = '';
  switch (items[0].in) {
    case 'query':
      title = apiRefMessages.queryParamTitle;
      break;
    case 'path':
      title = apiRefMessages.pathParamTitle;
      break;
    case 'body':
      title = apiRefMessages.bodyParamTitle;
      break;
    case 'header':
      title = apiRefMessages.headerParamTitle;
      break;
    case 'response':
      title = apiRefMessages.responseParamTitle;
      break;
    default:
      title = '';
      break;
  }
  return (
    <Typography type="DESKTOP_HEADING_ALLCAPS" sx={useStyles.typographyStyle}>
      {title}
    </Typography>
  );
};

export default ParameterTitle;
