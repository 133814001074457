import { Box, BoxProps, styled, width } from '@mui/system';
import React from 'react';
import theme, { COLORS } from '../../../utils/theme';
import Typography from '../../common/Typography';
import apiRefMessages from '../../../utils/messages/en/apiRefMessages';

const useStyles = {
  requiredStyle: {
    color: COLORS.TEXT_DELETE,
    marginLeft: '8px',
  },
  typeStyle: {
    color: COLORS.TEXT_SECONDARY,
    marginLeft: theme.spacing(1),
  },
  keyStyle: {
    color: COLORS.SECONDARY_STORM,
  },
  minMaxStyle: {
    color: COLORS.TEXT_SECONDARY,
    whiteSpace: "nowrap", 
  },
};

const StyledBox = styled((props: BoxProps) => <Box {...props} />)(() => ({
  display: 'flex',
  paddingTop: theme.spacing(2),
  alignItems: 'center',
}));

export interface ParamKeyProps {
  name: string;
  type?: string;
  required?: boolean | string;
  style?: object;
  format?: string | undefined;
  min?: string;
  max?: string;
}

const ParamKey: React.FC<ParamKeyProps> = (props) => {
  const { name, type, required, style, format, min, max } = props;
  return (
    <StyledBox sx={style}>
      <Typography sx={useStyles.keyStyle} type="DESKTOP_CODE_PRIMARY">
        {name}
      </Typography>
      {type && 
        <Typography sx={useStyles.typeStyle} type="DESKTOP_BODY_SMALL_TEXT">
          {type}
        </Typography>
      }
      {format &&
        <Typography sx={useStyles.typeStyle} type="DESKTOP_BODY_SMALL_TEXT">
          {'(' + format + ')'}
        </Typography>
      }
      {(min !== undefined) && (
        <Typography sx={useStyles.minMaxStyle} type="DESKTOP_BODY_SMALL_TEXT">
          {`, min ${min}`}
        </Typography>
      )}
      {(max !== undefined) && (
        <Typography sx={useStyles.minMaxStyle} type="DESKTOP_BODY_SMALL_TEXT">
          {`, max ${max}`}
        </Typography>
      )}
      {required && 
        <Typography sx={useStyles.requiredStyle} type="DESKTOP_BODY_SMALL_TEXT">
          {apiRefMessages.parameterRequired}
        </Typography>
      }
    </StyledBox>
  );
};

export default ParamKey;
