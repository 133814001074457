import { Button, Chip } from '@mui/material';
import React from 'react';
import apiRefMessages from '../../../utils/messages/en/apiRefMessages';
import theme, { COLORS } from '../../../utils/theme';
import Typography from '../../common/Typography';
import Vector from '../../../../static/images/vector.svg'

const useStyles = {
  chip: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(0),
    height: '17px',
    '& .MuiChip-label': {
      padding: '2px 4px 0px 4px',
    },
    backgroundColor: '#0000000F',
    margin: '1px 4px 1px 0px',
  },
  chipLabelStyle: {
    color: COLORS.SECONDARY_LUNAR,
  },
  defaultStyles: {
    color: 'rgba(62, 62, 78, 0.75)',
    fontWeight: '500',
  },
  ellipsisChip: {
    border: `1px solid #0000002E`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    height: '16px',
    width: '22px',
    borderRadius: '4px',
    cursor: 'pointer',
    '& .MuiChip-label': {
      padding: '2px 4px 4px 4px',
    },
  },
};

const ParamDetail: React.FC<{ param: any }> = (props) => {
  const { param } = props;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const maxVisibleEntries = 4; 
  const getDefaultValue = (param) => {
    if (param.default || param.default===0) {
      return param.default.toString();
    } else {
      if (
        param.schema &&
        (param.schema.default || param.schema.default === 0)
      ) {
        return param.schema.default.toString();
      } else {
        return undefined;
      }
    }
  };
  const getEnumValue = (param) => {
    if (param.enum) {
      return param.enum;
    } else {
      if (param.schema && param.schema.enum) {
        return param.schema.enum;
      } else {
        return undefined;
      }
    }
  };
  const buildDefaultValue = (param) => {
    if (getDefaultValue(param) && getEnumValue(param)) {
      return (
        <Typography
          type="DESKTOP_BODY_PARAGRAPH"
          component="div"
          sx={useStyles.defaultStyles}
        >
          {apiRefMessages.defaultTitle}
          <Chip
            label={
              <Typography
                type="DESKTOP_CODE_ALLCAPS"
                sx={useStyles.chipLabelStyle}
              >
                {getDefaultValue(param)}
              </Typography>
            }
            sx={useStyles.chip}
          />
        </Typography>
      );
    } else {
      return (
        <Typography
          type="DESKTOP_BODY_PARAGRAPH"
          component="div"
        >
          {apiRefMessages.defaultTitle + getDefaultValue(param)}
        </Typography>
      );
    }
  };

  const enumValues = getEnumValue(param);

  return (
    <>
      {getDefaultValue(param) && buildDefaultValue(param)}
      {getEnumValue(param) && 
        <Typography
          type="DESKTOP_BODY_PARAGRAPH"
          component="div"
          sx={useStyles.defaultStyles}
        >
          {apiRefMessages.enumTitle}
          {(isExpanded ? enumValues : enumValues.slice(0, maxVisibleEntries)).map((data, idx) => (
              <Chip
                label={
                  <Typography
                    type="DESKTOP_CODE_ALLCAPS"
                    sx={useStyles.chipLabelStyle}
                  >
                    {data}
                  </Typography>
                }
                key={idx}
                sx={useStyles.chip}
              />
            ))}
             {!isExpanded && enumValues.length > maxVisibleEntries && (
            <Chip
            label={
            <img src={Vector}/>
          }
            onClick={() => setIsExpanded(true)}
            sx={{ ...useStyles.ellipsisChip, cursor: 'pointer' }}
          />
          )}
        </Typography>
      }
    </>
  );
};

export default ParamDetail;
