import React, { useState } from 'react';
import theme, { COLORS } from '../../../utils/theme';
import { Box } from '@mui/system';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '../../common/Typography';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import MDXComponents from '../../docs/MDXComponents';

const useStyles = {
  paramDescription: {
    paddingLeft: '18px',
    paddingBottom: '4px',
  },
  paramDescriptionCollapsed: {
    paddingLeft: '18px',
    paddingBottom: '4px',
  },
  paramHeadearStyle: {
    display: 'flex',
  },
  iconStyle: {
    height: '20px',
    width: '20px',
    fontSize: '14px',
    padding: '0px 2px',
    color: COLORS.PRIMARY_ACTIONS,
    cursor: 'pointer',
  },
  grayBackground: {
    backgroundColor: theme.palette.grey[100],
  },
  defaultBackground: {
    backgroundColor: theme.palette.common.white,
  },
  inheritBackground: {
    backgroundColor: 'inherit',
  },
  innerObjectStyle: {
    marginLeft: '25px',
    marginRight: '12px',
  },
  reactMarkdownStyles:{
    marginBlock: 'unset',
    marginInline: 'unset',
    display: 'unset',
  },
  buttonStyle: {
    color: COLORS.PRIMARY_ACTIONS,
  }
};
export interface ObjectParamProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  header: any;
  name: any;
  description?: string;
  colorFlag?: boolean;
  defaultValue?: string;
  showToggle?: boolean;
}
const ObjectParam: React.FC<ObjectParamProps> = (props) => {
  const { header, name, description, colorFlag, defaultValue, showToggle } = props;
  const [expandable, setExpandable] = useState(true);
  if (!name) {
    return <Box key={name}>{props.children}</Box>;
  }
  const shortCodes = {
  a:MDXComponents.a,
  p: (props) => {
    return (
      <Typography
      type="DESKTOP_BODY_PARAGRAPH"
       sx={useStyles.reactMarkdownStyles}
        {...props}
      />
    );
  }
  };
  return (
    <Box key={name}>
      <Box sx={useStyles.paramHeadearStyle}>
        <div style={{paddingLeft: '18px'}}></div>
        {header}
      </Box>
      {defaultValue &&
        <Typography whiteSpace="pre-line"
          type="DESKTOP_BODY_PARAGRAPH"
          sx={useStyles.paramDescriptionCollapsed}> 
          Default: {JSON.stringify(defaultValue)}
        </Typography>
      }
      {description && 
        <Typography
          whiteSpace="pre-line"
          type="DESKTOP_BODY_PARAGRAPH"
          sx={
            expandable
              ? useStyles.paramDescription
              : useStyles.paramDescriptionCollapsed
          }
        >
          <ReactMarkdown rehypePlugins={[rehypeRaw]} components={shortCodes}>
            {description}
          </ReactMarkdown>
        </Typography>
      }
      {showToggle && expandable &&
        (<div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          cursor: 'pointer',
          paddingLeft: '14px',
          paddingBottom: '8px'
        }}
          onClick={() => setExpandable(false)}>
          <ExpandMoreIcon
            sx={useStyles.iconStyle}
          />
          <Typography
            type="DESKTOP_BODY_PARAGRAPH"
            sx={useStyles.buttonStyle}
          >
            Show properties
          </Typography>
        </div>)
      }
      {showToggle && !expandable &&
        (<div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          cursor: 'pointer',
          paddingLeft: '14px',
        }}
          onClick={() => setExpandable(true)}>
          <ExpandLessIcon
            sx={useStyles.iconStyle}
          />
          <Typography
            type="DESKTOP_BODY_PARAGRAPH"
            sx={useStyles.buttonStyle}
          >
            Hide properties
          </Typography>
        </div>)
      }
      <Box
        display={expandable ? 'none' : 'block'}
        sx={{
          ...useStyles.innerObjectStyle,
          borderLeft: !expandable ? '2px solid #0000002E' : 'none',
          paddingLeft: !expandable ? '1px' : '12px',
        }}
      >
       {props.children}
      </Box>
    </Box>
  );
};

export default ObjectParam;
